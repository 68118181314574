<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-08-27 10:40:30
 * @LastEditors: zhangziyu
 * @LastEditTime: 2024-09-26 11:02:01
-->
<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-08-27 10:40:30
 * @LastEditors: liang
 * @LastEditTime: 2024-08-27 16:36:06
-->
<template>
  <div class="invite" style="overflow: hidden">
    <!-- <div class="invite-bg">
      <img
        src="@/assets/img/invite/open-beta-invite_bg.png"
        class="invite-bg-img"
      />
    </div> -->
    <div class="acc-num">
      当前帐号：{{ inviterMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}
    </div>
    <div class="content">
      <div style="text-align: center; padding: 0 20px 0 8px">
        <img src="@/assets/img/invite/get-money.png" style="width: 100%" />
      </div>
      <!-- <div style="padding-top: 13px; padding-bottom: 13px" v-if="!isNew">
        <InviteLogin
          :inviterMobile="inviterMobile"
          :invitationMethod="invitationMethod"
        ></InviteLogin>
      </div> -->
      <div class="invite-way" style="padding-bottom: 10px">
        <InviteWay></InviteWay>
      </div>
      <div class="invite-code">
        <div class="invite-code-img"></div>
        <div class="invite-code-left"></div>
        <div class="invite-code-text"></div>
      </div>
      <!-- <div class="invite-record">
        <InviteRecord></InviteRecord>
      </div> -->
      <!-- 活动规则 tag-->
      <div class="rightRuleTag" @click="ruleShow = true">
        <div class="rightRuleTagContent">查<br />看<br />活<br />动<br />规<br />则</div>
      </div>
      <!-- <div class="rightRuleTag xxxx" @click="xxxx">
        分<br />享<br />朋<br />友<br />圈
      </div> -->
      <van-popup v-model="ruleShow" closeable round :style="{ width: '80%' }">
        <ActivityRule></ActivityRule>
      </van-popup>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import InviteWay from "./components/invite-way.vue";
import ActivityRule from "./components/activity-rule.vue";
import InviteLogin from "./components/invite-login.vue";
import { getURLParamsByKey } from "@/utils/index";
import { portalForward } from "@/api/invite";
import { bindOpenId } from "@/api/withdraw";
import wx from "weixin-js-sdk"; //微信sdk依赖
import request from "@/utils/request";
export default Vue.extend({
  components: { ActivityRule, InviteWay },
  data() {
    return {
      showShare: false,
      options: [{ name: "朋友圈", icon: "wechat-moments" }],
      ruleShow: false,
      inviterMobile: "",
      invitationMethod: "0",
      shareThree: false,
      baseUrl: "",
    };
  },
  mounted() {
    this.getSignature();

    document.title = "公测赢福利  纵享三重礼";
    this.inviterMobile = this.$store.getters.currentLoginMobile;
    let code = getURLParamsByKey("code") || "";
    if (code !== "") {
      bindOpenId(code, this.$store.getters.inviteToken).then((res) => {
        console.log(res);
      });
      history.replaceState(null, "", window.location.href.replace("code", "code1"));
    }
    this.baseUrl = process.env.VUE_APP_INVITE_URL;
  },
  methods: {
    hideOptionMenu() {
      wx.ready(function () {
        // 隐藏
        // wx.hideMenuItems({
        //   menuList: ['menuItem:share:timeline', 'menuItem:share:appMessage'],
        // });
        wx.hideOptionMenu();
      });
    },
    showOptionMenu() {
      wx.ready(function () {
        // 显示
        wx.showOptionMenu();
      });
    },
    getPortalForward() {
      portalForward().then(() => {
        // this.shareThree = false;
        // this.showShare = false;
      });
    },
    getSignature() {
      this.$nextTick(() => {
        let url = `${this.baseUrl}/openBetaShareEwm?inviterMobile=${this.$store.getters.currentLoginMobile}`;
        request({
          url: `${this.baseUrl}/weChat/getSignature?url=` + url,
          method: "get",
        })
          .then((res) => {
            console.log(88888, res);
            const { timestamp, noncestr, signature } = res;
            wx.config({
              debug: false,
              appId: "wx17081ce61493aba9",
              timestamp: +timestamp,
              nonceStr: noncestr,
              signature,
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
              ],
            });
            wx.ready(() => {
              const title = "云算房一智能造价云平台";
              const desc = "活动邀请";
              const link = `${this.baseUrl}/openBetaShare?inviterMobile=${this.$store.getters.currentLoginMobile}`;
              const imgUrl =
                "https://hzjt-ui-publiclib.oss-cn-beijing.aliyuncs.com/YYFUPT/log1o.png";
              wx.updateAppMessageShareData({
                title,
                desc,
                link,
                imgUrl,
                success(res) {
                  console.log("成功:" + JSON.stringify(res));
                },
                fail(res) {
                  console.log("失败:" + JSON.stringify(res));
                },
              });
              wx.updateTimelineShareData({
                title,
                desc,
                link,
                imgUrl,
                success(res) {
                  console.log("成功:" + JSON.stringify(res));
                },
                fail(res) {
                  console.log("失败:" + JSON.stringify(res));
                },
              });
              wx.error(function (res) {
                console.log("config1:" + JSON.stringify(res));
              });
              this.hideOptionMenu();
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    BindUserOpenId() {
      // let currentUrl = window.location.href;
      // const ua = window.navigator.userAgent.toLowerCase();
      //  else if (code == "") {
      //   getWechatCode(ua, currentUrl, this.$router);
      // } else if (code !== "") {
      //   localStorage.setItem("wxCode", code);
      //   this.$store.commit("SET_WXCODE", code);
      //   // history.back();
      //   console.log(this.$router);
      //   this.$router.go(-1);
      // }
    },
    xxxx() {
      this.showShare = true;
    },
  },
});
</script>
<style lang="less" scoped>
.rightRuleTag {
  position: fixed;
  z-index: 5;
  top: 200px;
  right: 0;
  width: 28px;
  height: 106px;
  // background: linear-gradient(-15deg, #deeefd, #ffffff);
  padding: 10px 0;
  // box-shadow: 0px 1px 9px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  // border-radius: 5px 0 0 5px;
  font-weight: bold;
  font-size: 12px;
  color: #2a1000;
  line-height: 15px;
  background: url("~@/assets/img/invite/rule_bg.png") center top no-repeat;
  background-size: 100%;
}
.rightRuleTag.xxxx {
  top: 300px;
  color: orangered;
  min-height: 84px;
}
.invite {
  width: 100%;
  position: relative;
  min-height: 100vh;
  background: url("~@/assets/img/invite/open-beta-invite_bg.jpg") center top no-repeat;
  background-size: 100%;
  background-color: #3ba6fc;
  &-bg {
    &-img {
      width: 100%;
    }
  }
  .content {
    position: relative;
    padding-top: 180px;
    z-index: 27;
    .invite-record {
      margin-top: 20px;
    }
    .activity-rule {
      margin-top: 20px;
    }
  }
  &-code {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 20px;
    &-img {
      width: 58px;
      height: 58px;
      background: url("~@/assets/img/invite/code.png") center top no-repeat;
      background-size: 100%;
    }
    &-left {
      width: 23px;
      height: 16px;
      background: url("~@/assets/img/invite/to_left.png") center top no-repeat;
      background-size: 100%;
      margin: 0 20px;
    }
    &-text {
      width: 135px;
      height: 38px;
      background: url("~@/assets/img/invite/code_text.png") center top no-repeat;
      background-size: 100%;
    }
  }
}
.acc-num {
  font-size: 11px;
  color: #fff;
  position: absolute;
  right: 15px;
  top: 20px;
  z-index: 22;
}
.imgsharethree {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2099;
  width: 70%;
}
</style>
